import {
  faServer as freeFasFaServer,
  faColumns as freeFasFaColumns,
  faCloudUploadAlt as freeFasFaCloudUploadAlt,
  faShieldAlt as freeFasFaShieldAlt,
  faCode as freeFasFaCode,
  faTachometerAlt as freeFasFaTachometerAlt,
  faBolt as freeFasFaBolt,
  faRobot as freeFasFaRobot,
  faDesktop as freeFasFaDesktop,
  faPlug as freeFasFaPlug,
  faPhone as freeFasFaPhone,
  faEnvelope as freeFasFaEnvelope
} from '@fortawesome/free-solid-svg-icons'

export default {freeFasFaServer, freeFasFaColumns, freeFasFaCloudUploadAlt, freeFasFaShieldAlt, freeFasFaCode, freeFasFaTachometerAlt, freeFasFaBolt, freeFasFaRobot, freeFasFaDesktop, freeFasFaPlug, freeFasFaPhone, freeFasFaEnvelope}