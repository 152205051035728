export default defineNuxtPlugin(() => {
	if (typeof window !== 'undefined' && window.Typebot) {
		window.Typebot.initBubble({
			typebot: 'lead-scoring-fjgmkxm',
			previewMessage: {
				message: 'Are you interested in our services?',
				autoShowDelay: 5000,
			},
			theme: {
				button: { backgroundColor: '#A855F7' },
				chatWindow: { backgroundColor: '#1F2937' },
			},
		});
	}
});
