export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"Custom SaaS and web development with performance and accessibility in mind."}],"link":[{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"href":"https://fonts.gstatic.com","crossorigin":"anonymous","type":"font/woff2","rel":"preload","as":"font"}],"style":[],"script":[{"src":"https://cdn.jsdelivr.net/npm/@typebot.io/js@0.3/dist/web.js","async":true,"defer":true,"type":"module"},{"innerHTML":"\n\t\t\tvar _iub = _iub || [];\n\t\t\t_iub.csConfiguration = {\n\t\t\t  \"siteId\": 3933098,\n\t\t\t  \"cookiePolicyId\": 75117075,\n\t\t\t  \"lang\": \"it\",\n\t\t\t  \"storage\": {\"useSiteId\": true}\n\t\t\t};\n\t\t  ","type":"text/javascript"},{"src":"https://cs.iubenda.com/autoblocking/3933098.js","async":true,"defer":true},{"src":"https://cdn.iubenda.com/cs/tcf/stub-v2.js","async":true,"defer":true},{"src":"https://cdn.iubenda.com/cs/tcf/safe-tcf-v2.js","async":true,"defer":true},{"src":"https://cdn.iubenda.com/cs/gpp/stub.js","async":true,"defer":true},{"src":"https://cdn.iubenda.com/cs/iubenda_cs.js","async":true,"defer":true}],"noscript":[],"title":"Fezz – Digital Solutions for Web and SaaS","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false

export const purgeCachedData = true